<template>
    <h1>ООО "Аудиторская фирма "Гарант"</h1>
    <p class="mb-5">Отчет аудиторской организации ООО "Аудиторская фирма "Гарант" за год, окончившийся 31 декабря 2023г.</p>
    <div class="row align">
      <div class="col-6 text-start">
        <!-- 1       -->
        <h5 class="mb-3">1. Информация об аудиторской организации</h5>
        <b>Полное наименование:</b>
        <p>Общество с ограниченной ответственностью "Аудиторская фирма "Гарант"</p>
        <b>Сокращенное наименование:</b>
        <p>ООО "Аудиторская фирма "Гарант"</p>
        <b>Адрес в пределах местонахождения:</b>
        <p>	625007, Россия, Тюменская обл., г.Тюмень, ул.В.Гнаровской, д.12, корп.4, кв.64</p>
        <b>Номер телефона:</b>
        <p>+7(3452) 90-66-56</p>
        <b>Адрес электронной почты:</b>
        <p>garant_af@mail.ru</p>
        <hr>
        <!-- 2       -->
        <h5 class="mb-3">2. Информация о наличии права аудиторской организации оказывать аудиторские услуги</h5>
        <p>ООО "Аудиторская фирма "Гарант" является членом Саморегулируемой организации аудиторов Ассоциация «Содружество».
          Номер в реестре аудиторов и аудиторских организаций саморегулируемой организации аудиторов 11706130811
        </p>
        <b>Дата внесения сведений в реестр аудиторов и аудиторских организаций саморегулируемой организации аудиторов:</b>
        <p>11 октября 2017 года</p>
        <hr>
        <!-- 3       -->
        <h5 class="mb-3">3. Информация о структуре аудиторской организации</h5>
        <b>Органы управления:</b>
        <p>Высший орган управления - общее собрание участников</p>
        <b>Единоличный исполнительный орган:</b>
        <p>Директор Абрамова Наталья Ивановна. Деятельность директора регулируется нормами, изложенными в уставе</p>
        <hr>
        <!-- 4       -->
        <h5 class="mb-3">4. Информация о лицах, связанных с аудиторской организацией</h5>
        <b>Перечень филиалов и представительств и их мест нахождения:</b>
        <p>Отсутствуют</p>
        <b>Перечень дочерних обществ аудиторской организации:</b>
        <p>Отсутствуют</p>
        <b>Наименование организации, по отношению к которой аудиторская организация является дочерним обществом:</b>
        <p>Не применимо</p>
        <b>Перечень аудиторских организаций, участвующих в уставном капитале аудиторской организации:</b>
        <p>Не применимо</p>
        <b>Размер доли уставного капитала аудиторской организации, принадлежащей всем аудиторам этой аудиторской организации:</b>
        <p>100%</p>
        <b>в т.ч. размер доли уставного капитала аудиторской организации, принадлежащей всем аудиторам,
          являющимся работниками аудиторской организации по основному месту работы:
        </b>
        <p>-</p>
        <b>в т.ч. размер доли уставного капитала аудиторской организации, принадлежащей всем аудиторам, являющимся работниками аудиторской организации по совместительству:</b>
        <p>100%</p>
        <b>Перечень бенифициарных владельцев аудиторской организации:</b>
        <p>Абрамова Наталья Ивановна</p>
        <b>Перечень иностранных граждан, лиц без гражданства, иностранных юридических лиц, международных компаний, являющихся контролирующими лицами аудиторской организации:</b>
        <p>Не применимо</p>
        <b>Наименование российской и (или) международной сети аудиторских организаций, членом которой является аудиторская организация:</b>
        <p>Не применимо</p>
        <hr>
        <!-- 5       -->
        <h5 class="mb-3">5. Информация об организации и обеспечении соблюдения аудиторской организацией требований
          профессиональной этики и независимости
        </h5>
        <p>Организация и обеспечение соблюдения аудиторской организацией требований профессиональной
          этики и независимости осуществляется в соответствии с требованиями
          Федерального закона "Об аудиторской деятельности" №307-ФЗ от 30.12.2008г.
        </p>
        <b>Заявление руководителя аудиторской организации о соблюдении аудиторской организацией и аудиторами
          требований профессиональной этики и независимости:
        </b>
        <p>ООО "Аудиторская фирма "Гарант" и ее аудиторы соблюдают требования профессиональной этики и независимости
          в соответствии с Федеральным законом «Об аудиторской деятельности» № 307-ФЗ от 30.12.2008г.
        </p>
        <b>Описание системы вознаграждения руководства аудиторской организации,
          руководителей аудита, в том числе факторов, влияющих на размер вознаграждения:
        </b>
        <p>
          Система вознаграждения руководства организации, руководителей аудита, в том числе факторы,
          влияющие на размер вознаграждения, установлены действующей системой оплаты труда в организации.
          Вознаграждение руководства аудиторской организации, руководителей аудита состоит из должностного
          оклада, премий, надбавок, компенсаций и доплат.
        </p>
        <b>Описание мер, принимаемых в аудиторской организации в целях обеспечения ротации руководителей аудита:</b>
        <p>Политика ротации руководителей аудита ООО "Аудиторская фирма "Гарант" соответствует требованиям законодательства,
          регулирующего аудиторскую деятельность. Информация о необходимости ротации сотрудников предоставляется директором до
          начала планирования работ.
        </p>
      </div>

      <!--Right-->
      <div class="col-6">
        <!-- 6       -->
        <h5 class="mb-3">6. Информация о контроле (надзоре) за деятельностью (качества работы) аудиторской организации</h5>
        <b>Заявление руководителя аудиторской организации о наличии и результативности системы внутреннего
          контроля аудиторской организации, ее соответствии Международному стандарту контроля качества 1
        </b>
        <p>
          ООО "Аудиторская фирма "Гарант" отличает понимание специфики бизнеса, качество услуг и комплексный подход к
          решению задач клиентов. В организации внедрена система контроля качества, позволяющая совершенствовать процесс
          оказания услуг, утверждены внутренние методики и документы, изменяемые по мере модернизации практики выполнения
          проектов. Система качества работы ООО "Аудиторская фирма "Гарант" построена в соответствии с требованиями
          Федерального закона "Об аудиторской деятельности" №307-ФЗ, МСК 1  "Управление качеством в аудиторских организациям,
          проводящих аудит или обзорные проверки финансовой отчетности, а также выполняющих прочие задания,
          обеспечивающие уверенность, или задания по оказанию сопутствующих услуг", Кодекса профессиональной этики аудиторов,
          Правил независимости аудиторов и аудиторских организаций.
          При трудоустройстве в ООО "Аудиторская фирма "Гарант" сотрудники, оказывающие  профессиональные услуги,
          в обязательном порядке знакомятся с Правилами независимости аудиторов и аудиторских организаций,
          Кодексом профессиональной этики аудиторов, «Правилами независимости ООО "Аудиторская фирма "Гарант",
          «Правилами внутреннего контроля ООО "Аудиторская фирма "Гарант".
          ООО "Аудиторская фирма "Гарант" ежегодно проводит процедуру подтверждения соблюдения принципов и процедур независимости
          всех сотрудников, вовлеченных в оказание профессиональных услуг.
          В соответствии с внутренними регламентами ООО "Аудиторская фирма "Гарант" в рамках каждого задания проводится процедура принятия клиента,
          включающая подтверждение независимости и отсутствия конфликта интересов, как на уровне организации, так и на уровне ее сотрудников.
          В рамках выполнения заданий каждый сотрудник, участвующий в выполнении задания, в письменном виде подтверждает свою независимость
          в отношении конкретного клиента.
          Концептуальный подход к независимости, применяемый в ООО "Аудиторская фирма "Гарант", описан в
          «Правилах независимости ООО "Аудиторская фирма "Гарант", принятых на основе Кодекса профессиональной этики
          аудиторов и Правил независимости аудиторов и аудиторских организаций.
        </p>
        <b>Сведения о внешних проверках деятельности аудиторской организации, проведенных в течение трех лет:</b>
        <p>
          В отношении ООО "Аудиторская фирма "Гарант" на основании постановления Комиссии по контролю качества
          СРО ААС от «05» сентября 2022 г. №1821-22 проведена плановая проверка за период деятельности с 01 января 2017г.
          по 31 декабря 2021 г.
        </p>
        <b>Меры дисциплинарного и иного воздействия, примененные в отношении аудиторской организации в течение года,
          в котором раскрывается информация и предшествующему ему года:
        </b>
        <p>Вынесено предупреждение в письменной форме о недопустимости нарушения стандартов аудиторской деятельности</p>
        <hr>
        <!-- 7       -->
        <h5 class="mb-3">7. Информация об аудиторах работающих в аудиторской организации по трудовому договору</h5>
        <b>Численность работающих в аудиторской организации по основному месту работы и по совместительству аудиторов,
          доля таких аудиторов в общей численности аудиторов, работающих в аудиторской организации по трудовому договору
          (по состоянию на 1 января 2024 года):
        </b>
        <p>6 аудиторов, из них:</p>
        <p>- 4 по основному месту работы (67%)</p>
        <p>- 2 по совместительству (33%)</p>
        <b>Численность аудиторов, имеющих квалификационный аттестат аудитора, выданный саморегулируемой организацией аудиторов:</b>
        <p>Отсутствуют</p>
        <b>Заявление руководителя аудиторской организации о соблюдении аудиторами, работающими в аудиторской организации,
          требования о прохождении обучения по программам повышения квалификации:
        </b>
        <p>
          Сотрудники ООО «Аудиторская фирма "Гарант", имеющие квалификационные аттестаты аудитора, в обязательном порядке
          проходят обучение в объеме 40 часов в соответствии с их квалификацией и опытом работы в аудите.
          Программы для прохождения обучения выбираются с учетом приоритетных тематик обучения по программам повышения квалификации
          аудиторов, рекомендованных Советом по аудиторской деятельности
        </p>
        <hr>
        <!-- 8       -->
        <h5 class="mb-3">8. Информация об аудируемых лицах и величине выручке от оказанных аудиторской организацией услуг</h5>
        <b>ЧПеречень общественно-значимых организаций, которым оказаны аудиторские услуги в течение не менее года,
          непосредственно предшествующего году, в котором раскрывается информация:
        </b>
        <p>Аудиторские услуги общественно-значимым организациям в 2023 году не оказывались</p>
        <b>
          Величина выручки от оказания аудиторских услуг и прочих связанных с аудиторской деятельностью услуг за год, непосредственно
          предшествующий году, в котором раскрывается информация, в том числе:
        </b>
        <p>13774</p>
        <b>величины выручки от оказания аудиторских услуг:</b>
        <p>8987</p>
        <b>величина выручки от оказания прочих связанных с аудиторской деятельностью услуг:</b>
        <p>4787</p>
        <b>Величина выручки от оказания аудиторских услуг и прочих связанных с аудиторской деятельностью услуг общественно значимым организациям за год,
          непосредственно предшествующий году, в котором раскрывается информация, в том числе:
        </b>
        <p>-</p>
        <b>величины выручки от оказания аудиторских услуг:</b>
        <p>-</p>
        <b>величина выручки от оказания прочих связанных с аудиторской деятельностью услуг:</b>
        <p>-</p>
      </div>
    </div>
    <hr>
    <div class="text-end h5">Дата публикации 29 февраля 2024 года</div>
</template>

<script>
export default {
  name: 'Report-2023',
}
</script>

<style scoped>
  .align{
    text-align: justify;
  }
</style>

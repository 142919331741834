<template>
  <footer class="container py-5 footer">
    <div class="row">
      <div class="col-12 col-md">
        <a href="/" ><img src="@/assets/logo_white.png"></a>
      </div>
      <div class="col-6 col-md">
        <ul class="list-unstyled text-small">
          <li class="link-secondary">Общество с ограниченной ответственностью</li>
          <li class="link-secondary">"Аудиторская фирма "Гарант"</li>
          <li class="link-secondary">garant_af@mail.ru</li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <ul class="list-unstyled text-small">
          <li class="link-secondary">г.Тюмень, ул. В. Гнаровской, д.12, к. 4, кв. 64</li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <ul class="list-unstyled text-small">
          <li class="link-secondary">ООО "Аудиторская фирма "Гарант"</li>
          <li class="link-secondary">является членом Саморегулируемой организации аудиторов Ассоциация</li>
          <li class="link-secondary">«Содружество».</li>
          <li class="link-secondary">Номер в реестре аудиторов и аудиторских организаций</li>
          <li class="link-secondary">саморегулируемой организации аудиторов 11706130811</li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>

</style>

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Report2021 from "@/components/UI/Report-2021.vue";
import Report2022 from "@/components/UI/Report-2022.vue";
import Report2023 from "@/components/UI/Report-2023.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/info',
    name: 'info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "info" */ '@/views/InfoView.vue'),
    children: [
      {
        path: '2021',
        name: '2021',
        component: Report2021
      },
      {
        path: '2022',
        name: '2022',
        component: Report2022
      },
      {
        path: '2023',
        name: '2023',
        component: Report2023
      }
    ]

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <header class="site-header sticky-top py-1">
    <nav class="container d-flex flex-column flex-md-row justify-content-between">
      <a class="py-2" href="/" aria-label="Product">
        <img src="@/assets/logo.png" alt="home"/>
      </a>
      <router-link class="py-2 d-none d-md-inline-block align-self-center" :to="{name: 'home'}">Главная</router-link>
      <router-link class="py-2 d-none d-md-inline-block align-self-center" :to="{name: 'info'}">Информация</router-link>
      <router-link class="py-2 d-none d-md-inline-block align-self-center disabled" :to="{name: 'about'}">Аудит</router-link>
      <router-link class="py-2 d-none d-md-inline-block align-self-center" :to="{name: 'about'}">Контакты</router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style scoped>

</style>

<template>
  <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
    <div class="col-md-5 p-lg-5 mx-auto my-5">
      <h1 class="display-4 fw-normal">"ГАРАНТ"</h1>
      <p class="lead fw-normal">Аудиторская Аирма</p>
      <router-link class="btn btn-outline-secondary" :to="{name: 'info'}">Информация</router-link>
    </div>
    <div class="product-device shadow-sm d-none d-md-block"></div>
    <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
  </div>

  <div class="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
    <div class="bg-dark me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
      <div class="my-3 py-3">
        <h2 class="display-5">Обязательныый аудит</h2>
        <ul class="list-unstyled text-small">
          <li class="link-secondary" >Уверенность в соответсвии бухгалтерского учета и подготовки отчетности  требованиям Российского законодательства</li>
          <li class="link-secondary" >Независимое мнение о возможно допущенных ошибках и недочетах при подготовке отчетности</li>
          <li class="link-secondary" >Отчет, содержащий описание допущенных нарушений законодательства и рекомендации по их устранению</li>
          <li class="link-secondary" >Аудиторское заключение с выражением мнения о достоверности бухгалтерской отчетности</li>
        </ul>
      </div>
      <div class="bg-light shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>
    </div>
    <div class="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
      <div class="my-3 p-3">
        <h2 class="display-5">Налоговый аудит</h2>
        <ul class="list-unstyled text-small">
          <li class="link-secondary">Оценка налоговых рисков организации и пути сведения их к минимуму</li>
        </ul>
      </div>
      <div class="bg-dark shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'HomeView',
  components: {
    Header
  }
}
</script>

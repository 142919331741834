<template>
  <Header></Header>
  <main class="app">
    <router-view/>
  </main>
  <Footer></Footer>
</template>

<script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    }
  }
</script>

<style>
@import 'assets/style/index.css';
@font-face {
  font-family: "MainFont";
  src: local("MainFont"),
  url(./fonts/main-font.ttf) format("truetype");
}
.app{
  font-family: "MainFont",serif;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>

